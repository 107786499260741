import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./Tools.module.scss"
import { Toolset } from "./Toolset"
import Section from "../Section"

const {
  theme: {
    colors: {
      berry: { value: defaultPrimaryColor },
      black: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

export const Tools = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    toolsets,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    ankerslug,
  } = data

  const backgroundColor = colors[parity].value

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
    >
      {dynamicHeadline && (
        <DynamicHeadline
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
          className={styles.toolsHeadline}
        >
          {dynamicHeadline}
        </DynamicHeadline>
      )}
      <div className={styles.toolsToolsetWrapper}>
        {toolsets &&
          toolsets.map((toolset, i) => <Toolset key={i} set={toolset} />)}
      </div>
    </Section>
  )
}

export default Tools
