import { ArrowRightIcon } from "@radix-ui/react-icons"
import Link from "next/link"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./LinkList.module.scss"
import { LinkListItem } from "./LinkListItem"
const {
  theme: {
    colors: {
      berry: { value: defaultPrimaryColor },
      black: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig
export const LinkList = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    links,
    show_button: showButton,
    button_link: buttonLink,
    button_new_tab: buttonNewTab,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
  } = data

  const color = colors[parity].value

  return (
    <section className={styles.linkList}>
      <div className={styles.headlineWrapper}>
        <DynamicHeadline
          headlineType={headlineType?.value}
          headlineAppearance={headlineAppearance?.value}
          className={`${styles.headline}`}
        >
          {dynamicHeadline}
        </DynamicHeadline>
      </div>
      <div className={styles.linksWrapper}>
        {links &&
          links.map((link, i) => (
            <LinkListItem key={i} item={link} color={color} />
          ))}
        <div className={styles.blogLinkWrapper}>
          {showButton && buttonLink && (
            <Link href={buttonLink}>
              <a
                className={styles.blogLink}
                target={buttonNewTab ? "_blank" : "_self"}
              >
                <ArrowRightIcon />
              </a>
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

export default LinkList
