import { useState } from "react"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./PriceSlider.module.scss"
import Section from "../Section"

const {
  theme: {
    colors: {
      berry: { value: defaultPrimaryColor },
      black: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const PriceSlider = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    min_value: minValue = 6000,
    max_value: maxValue = 33000,
    promo_percent: percent = 50,
    promo_label: promoLabel = "Fördersumme",
    budget_label: budgetLabel = "Projektbudget",
    ankerslug,
  } = data
  const factor = parseInt(percent) / 100
  const defaultValue =
    Math.round((maxValue - minValue) / 2 / 1000) * 1000 + minValue
  const [value, setValue] = useState(defaultValue)
  const result = (value * factor).toLocaleString("de-DE")
  const style = colors[parity].value ? { "--color": colors[0].value } : {}

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.priceSlider}
      style={style}
    >
      <div className={styles.priceSlider_content}>
        {dynamicHeadline && (
          <DynamicHeadline
            headlineType={headlineType.value}
            headlineAppearance={headlineAppearance.value}
            className={styles.headline}
          >
            {dynamicHeadline}
          </DynamicHeadline>
        )}

        <div className={styles.interactive}>
          <div className={styles.wrapper}>
            <label htmlFor="price_slider">
              {`${budgetLabel}: `}
              <output htmlFor="price_slider">
                {Number(value).toLocaleString("de-DE")} €
              </output>
            </label>
            <div className={styles.sliderWrapper}>
              <span>{Number(minValue).toLocaleString("de-DE")}</span>
              <input
                type="range"
                name="price_slider"
                id="price_slider"
                className={styles.input}
                onChange={(e) => setValue(e.target.value)}
                step={1000}
                min={minValue}
                max={maxValue}
                defaultValue={defaultValue}
              />
              <span>{Number(maxValue).toLocaleString("de-DE")}</span>
            </div>
          </div>
          <div className={styles.resultWrapper}>
            <span className={styles.resultOverline}>{promoLabel}</span>
            <span className={styles.result}>{result} €</span>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PriceSlider
