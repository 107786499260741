import cn from "classnames"
import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import Image from "../Image"
import Letter from "../ui/Letter"
import LinkButton from "../ui/LinkButton"
import styles from "./TextImage.module.scss"
import Section from "../Section"

const {
  theme: {
    colors: {
      berry: { value: defaultPrimaryColor },
      black: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const TextImage = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
  noMargin,
}) => {
  const {
    textImageText: text,
    image,
    image_orientation,
    letter: letterObj,
    link: url,
    link_text: linkText,
    new_tab: newTab,
    headline_teaser: headlineTeaser,
    dynamic_headline: headline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    text_image_fill_background: fillBg,
    cta_link: cta_Link,
    ankerslug,
  } = data

  const fillColor = colors[1].value
  const bgColor = fillBg ? colors[1].value : "transparent"

  const letter = letterObj?.value || "v"
  const hasImage = !!image
  const hasLetter = letterObj && letter !== "none"
  const hasGraphic = hasImage || hasImage
  const hasBg = fillBg && bgColor !== "transparent"
  const graphicRight = image_orientation?.value === "right"

  return (
    <Section
      id={ankerslug}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={cn(styles.textImageWrapper, {
        [styles.noMargin]: noMargin,
        [styles.filled]: hasBg,
        [styles.letterOnly]: hasLetter && !hasImage,
        [styles.imageOnly]: !hasLetter && hasImage,
        [styles.noGraphic]: !hasGraphic,
      })}
      style={{
        backgroundColor: bgColor,
      }}
      container={false}
    >
      <div
        className={cn(styles.textImage, {
          [styles.flexReverse]: hasGraphic && graphicRight,
        })}
      >
        <div className={styles.graphicWrapper}>
          {hasLetter && (
            <Letter
              type={letter}
              fill={fillColor}
              className={cn(styles.letter, {
                [styles.letterLeft]: !graphicRight,
                [styles.letterRight]: graphicRight,
                [styles.letterReverse]: hasLetter && graphicRight,
              })}
            />
          )}
          {hasImage && (
            <div className={styles.imageWrapper}>
              <Image
                src={image.permalink}
                alt={image.alt}
                width={image.width}
                height={image.height}
                sizes="(max-width: 767px) 100vw, 50vw"
              />
            </div>
          )}
        </div>
        <div
          className={cn(styles.contentWrapper, {
            [styles.textBlack]: colors[1].name === "berry",
            [styles.textWhite]: colors[1].name !== "berry",
          })}
        >
          {headlineTeaser && (
            <p className={styles.headlineTeaser}>{headlineTeaser}</p>
          )}
          <DynamicHeadline
            headlineType={headlineType.value}
            headlineAppearance={headlineAppearance.value}
          >
            {headline}
          </DynamicHeadline>
          <div className={styles.text}>
            {text &&
              text.length > 0 &&
              text.map((block, i) => {
                switch (block.__typename) {
                  case "BardText":
                    return <div key={i}>{parse(block.text)}</div>
                  case "Set_DynamicContent_Text_KontaktButton":
                    return (
                      <LinkButton
                        key={i}
                        data={{
                          link_text: block.text_link_text,
                          link_url: "/kontakt",
                        }}
                      />
                    )
                  default:
                    return
                }
              })}
          </div>
          {url && (
            <LinkButton
              className={cn(
                styles.button,
                `${
                  !hasBg || bgColor === "transparent"
                    ? "button--cta-alt"
                    : "button--cta"
                } ${styles.ctaLink}`
              )}
              data={{
                link_url: url,
                link_text: linkText,
                link_blank: newTab,
              }}
            />
          )}
        </div>
      </div>
    </Section>
  )
}

export default TextImage
