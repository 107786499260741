import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../DynamicHeadline"
import LinkButton from "../ui/LinkButton"
import Grid from "./Grid"
import styles from "./List.module.scss"
import Paragraph from "./Paragraph"
import Section from "../Section"

const {
  theme: {
    colors: {
      berry: { value: defaultPrimaryColor },
      black: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const List = (props) => {
  const {
    data = {},
    parity = 0,
    colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
  } = props

  const {
    numbered_list: numberedList,
    default_list_link,
    default_list_link_text,
    default_list_new_tab = false,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    list_type: type,
    list: items,
    list_text: listText,
    ankerslug,
  } = data

  const ListType = numberedList ? "ol" : "ul"
  const primaryColor = colors[parity].value
  const secondaryColor = colors[parity ? 0 : 1].value

  if (type?.value === "grid") {
    return <Grid {...props} />
  }
  if (type?.value === "paragraph") {
    return <Paragraph {...props} />
  }
  return items ? (
    <Section
      id={ankerslug}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div className={styles.wrapper}>
        <DynamicHeadline
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
        >
          {dynamicHeadline}
        </DynamicHeadline>
        {listText && <div className={styles.listText}>{parse(listText)}</div>}
        <div className={styles.listWrapper}>
          <ListType className={styles.list}>
            {items.map((listItem, i) => {
              const {
                dynamic_headline: dynamicHeadline,
                headline_appearance: headlineAppearance,
                headline_type: headlineType,
                listElementText,
                link_text,
                link_url,
                link_blank,
              } = listItem

              return (
                <li className={styles.listItem} key={i}>
                  {dynamicHeadline && (
                    <DynamicHeadline
                      headlineType={headlineType.value}
                      headlineAppearance={headlineAppearance.value}
                    >
                      {dynamicHeadline}
                    </DynamicHeadline>
                  )}
                  {listElementText && parse(listElementText)}
                  {link_text && link_url && (
                    <LinkButton
                      data={{
                        link_text,
                        link_url,
                        link_blank,
                        show_arrow: true,
                      }}
                    />
                  )}
                </li>
              )
            })}
          </ListType>
          {default_list_link && (
            <LinkButton
              className={styles.link}
              data={{
                link_text: default_list_link_text,
                link_url: default_list_link,
                link_blank: default_list_new_tab,
                show_arrow: true,
              }}
            />
          )}
        </div>
      </div>
    </Section>
  ) : null
}

export default List
