// Deprecated: Letters are replaced with shapes.
// Use the Shape component instead.

import Shape from "../Shape"

const Letter = ({
  type = "v",
  fill = "black",
  className = "",
  forceLegacyLetter = false,
}) => {
  switch (type) {
    case "l":
      return forceLegacyLetter ? (
        <svg
          className={className}
          fill={fill}
          viewBox="0 0 1599 735"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1174.6 0.0763364L3.40916e-05 0.076102L0 780L1598.92 780L1598.92 469.431C1599.5 453.594 1596.85 437.805 1591.13 423.031C1585.41 408.256 1576.74 394.808 1565.65 383.508L1249.35 41.6421C1237.7 29.1484 1224.73 17.9673 1210.66 8.2968C1199.61 2.32823 1187.14 -0.51369 1174.6 0.0763364Z" />
        </svg>
      ) : (
        <Shape type={"corner-round"} fill={fill} className={className} />
      )
    case "l-short":
      return forceLegacyLetter ? (
        <svg
          className={className}
          fill={fill}
          viewBox="0 0 1091 754"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M461.726 0.082514L1091 0.0825065L1091 753.998L0.0845635 754L0.0845602 477.865C-0.544486 460.735 2.34123 443.657 8.56521 427.676C14.7892 411.696 24.2204 397.149 36.2817 384.926L380.408 45.0423C393.078 31.5284 407.192 19.4344 422.498 8.9743C434.522 2.51837 448.083 -0.55573 461.726 0.082514Z" />
        </svg>
      ) : (
        <Shape type={"corner-vertical"} fill={fill} className={className} />
      )
    case "l-square":
      return forceLegacyLetter ? (
        <svg
          className={className}
          fill={fill}
          viewBox="0 0 280 280"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M172.301 0.0308457L280 0.0356323L280 280L0.0296016 280L0.0315521 180.309C-0.203188 173.845 0.873664 167.401 3.19625 161.371C5.51884 155.341 9.03825 149.852 13.5391 145.24L141.956 16.9947C146.684 11.8955 151.951 7.33276 157.662 3.38586C162.149 0.949853 167.21 -0.209968 172.301 0.0308457Z" />
        </svg>
      ) : (
        <Shape type={"rect-h-rounded-half"} fill={fill} className={className} />
      )
    case "l-vertical-short":
      return forceLegacyLetter ? (
        <svg
          className={className}
          fill={fill}
          viewBox="0 0 284 410"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M283.969 173.518L283.969 410L0.000845012 410L0 0.0317791L104.008 0.0317791C110.461 -0.204618 116.893 0.87984 122.912 3.21882C128.932 5.55781 134.411 9.10208 139.015 13.6347L267.034 142.958C272.125 147.72 276.68 153.024 280.62 158.776C283.051 163.294 284.209 168.39 283.969 173.518Z" />
        </svg>
      ) : (
        <Shape type={"rect-vertical"} fill={fill} className={className} />
      )
    case "l-vertical":
      return forceLegacyLetter ? (
        <svg
          className={className}
          fill={fill}
          viewBox="0 0 285 787"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M284.715 172.248L284.715 786.851H0.746094L0.746094 0.28443L104.754 0.28443C111.207 0.0501072 117.639 1.12505 123.658 3.44351C129.678 5.76197 135.157 9.27514 139.761 13.768L267.781 141.957C272.871 146.676 277.426 151.934 281.366 157.635C283.798 162.114 284.955 167.166 284.715 172.248Z" />
        </svg>
      ) : (
        <Shape type={"rect-vertical"} fill={fill} className={className} />
      )
    case "o":
      return forceLegacyLetter ? (
        <svg
          className={className}
          fill={fill}
          viewBox="0 0 520 520"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M129.085 0C37.1284 0 0 42.7229 0 126.287V393.713C0 477.328 37.1284 520 129.085 520H391.882C483.787 520 520 477.328 520 393.713V126.287C520 42.7229 482.872 0 390.966 0H129.085Z" />
        </svg>
      ) : (
        <Shape type={"donut"} fill={fill} className={className} />
      )
    default:
      return forceLegacyLetter ? (
        <svg
          className={className}
          fill={fill}
          viewBox="0 0 820 465"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M655.756 464.848C660.531 465.325 665.35 464.675 669.827 462.951C674.304 461.227 678.313 458.478 681.53 454.925L807.729 319.344C811.615 315.921 814.72 311.706 816.837 306.984C818.954 302.261 820.032 297.141 819.999 291.968V0H611.336V249.877H563.352V0H0.0721277V292.104C-0.259193 297.195 0.553822 302.296 2.45151 307.032C4.3492 311.769 7.28358 316.022 11.04 319.481L143.134 454.719C146.352 458.273 150.361 461.022 154.837 462.746C159.314 464.469 164.134 465.119 168.908 464.643L655.756 464.848Z" />
        </svg>
      ) : (
        <Shape type={"circle"} fill={fill} className={className} />
      )
  }
}

export default Letter
