import cn from "classnames"
import parse from "html-react-parser"
import { DynamicHeadline } from "../DynamicHeadline"
import styles from "./HomeHero.module.scss"
import Section from "../Section"
import Shape from "../ui/Shape"
import { defaultColors, getColors } from "../../utils/color-combinations"

export const HomeHero = ({ data = {} }) => {
  const {
    hero_images: images,
    hero_linked_entries: entries,
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    teaser_title: teaserTitle,
    teaser_text: teaserText,
    content_text: contentText,
  } = data

  const colors = getColors(data)
  const fillColor = colors[1].value

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={cn("home-hero", styles.homeWrapper)}
      container={false}
    >
      <div className={styles.backgroundElements}>
        <Shape type="disphere-d" fill={fillColor} className={styles.shapeD} />
        <Shape
          type="half-circle"
          fill={fillColor}
          className={styles.shapeHalfCircle}
        />
        <Shape type="circle" fill={fillColor} className={styles.shapeCircle} />
      </div>

      {dynamicHeadline && (
        <div className={styles.headlineWrapper}>
          {teaserTitle && (
            <h1 className="text-lg mb-2 md:text-3xl font-medium md:mb-8">
              {teaserTitle}
            </h1>
          )}
          <DynamicHeadline
            className="w-3/4 hero-text"
            headlineType={headlineType.value}
            headlineAppearance={headlineAppearance.value}
          >
            {dynamicHeadline}
          </DynamicHeadline>
          {teaserText && (
            <div className={cn(styles.text, "teaser")}>{parse(teaserText)}</div>
          )}
        </div>
      )}
    </Section>
  )
}

export default HomeHero
