import styles from "./AuthorBox.module.scss"
import Image from "../Image"
import cx from "classnames"
import useTranslation from "next-translate/useTranslation"
import Section from "../Section"

const AuthorBox = ({ data = {} }) => {
  const { t, lang } = useTranslation("common")

  // console.log("AuthorBox", data)

  return data?.authors?.length ? (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={styles.section}
    >
      <div className={styles.sectionInner}>
        {data.authors.map((authorData, i) => (
          <div key={authorData.author.id} className={styles.authorBoxWrapper}>
            {/*<div className={styles.shapes}>*/}
            {/*  <Letter type="l-vertical-short" className={styles.shape} />*/}
            {/*  <Letter type="l-vertical-short" className={styles.shape} />*/}
            {/*</div>*/}
            <div className={cx(styles.authorBox)}>
              {/*<div className={cx(styles.shapes, styles.mobile)}>*/}
              {/*  <Letter type="l-vertical-short" className={styles.shape} />*/}
              {/*  <Letter type="l-vertical-short" className={styles.shape} />*/}
              {/*</div>*/}
              <div className={styles.image}>
                <Image
                  src={authorData?.author?.avatar.permalink}
                  alt={authorData?.author?.avatar?.alt}
                  width={authorData?.author?.avatar.width}
                  height={authorData?.author?.avatar.height}
                  sizes="(min-width: 0px) 188px"
                />
              </div>
              <div className={styles.content}>
                <div className={styles.overline}>
                  {authorData?.overline ??
                    t(
                      `authorbox.overline.${i === 0 ? "main" : "co"}_${
                        authorData?.author?.sex?.value ?? "m"
                      }`
                    )}
                </div>
                <div className={styles.name}>
                  {authorData?.author?.title},{" "}
                  {authorData?.position ??
                    t(
                      `authorbox.expert.default_${
                        authorData?.author?.sex?.value ?? "m"
                      }`
                    )}
                </div>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html:
                      (authorData?.intro_sentence
                        ? `<p>${authorData?.intro_sentence}</p>`
                        : "") + authorData?.author?.description,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Section>
  ) : null
}

export default AuthorBox
