import parse from "html-react-parser"
import { twCustomConfig } from "utils/tw-config"
import { DynamicHeadline } from "../../DynamicHeadline"
import { ListItem } from "./ListItem"
import styles from "./Paragraph.module.scss"
import Section from "../../Section"

const {
  theme: {
    colors: {
      berry: { value: defaultPrimaryColor },
      black: { value: defaultSecondaryColor },
    },
  },
} = twCustomConfig

export const Paragraph = ({
  data = {},
  parity = 0,
  colors = [{ value: defaultPrimaryColor }, { value: defaultSecondaryColor }],
}) => {
  const {
    dynamic_headline: dynamicHeadline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
    list: items,
    numbered_list,
    list_text: listText,
    ankerslug,
  } = data

  const primaryColor = colors[1].value ?? colors[0].value?.DEFAULT

  const ListType = numbered_list ? "ol" : "ul"

  return (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      id={ankerslug}
      className={styles.paragraphListWrapper}
    >
      <DynamicHeadline
        headlineType={headlineType.value}
        headlineAppearance={headlineAppearance.value}
      >
        {dynamicHeadline}
      </DynamicHeadline>
      {listText && (
        <div className={styles.listText}>
          <span className="text-white">{parse(listText)}</span>
        </div>
      )}
      <ListType>
        {items?.length &&
          items.map((item, i) => (
            <ListItem
              key={i}
              item={item}
              order={i + 1}
              type={ListType}
              color={primaryColor}
            />
          ))}
      </ListType>
    </Section>
  )
}

export default Paragraph
