import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons"
import cn from "classnames"
import { useRef } from "react"
import Slider from "react-slick"
import { DynamicHeadline } from "../DynamicHeadline"
import Image from "../Image"
import LinkButton from "../ui/LinkButton"
import styles from "./ReferencesSlider.module.scss"
import Section from "../Section"

const Slide = ({ reference, order }) => {
  const { reference_images: images, title } = reference

  const titleImage = images?.length ? images[0] : null

  const letter = order % 2 === 0 ? "l" : "v"

  const color =
    order % 5 === 0
      ? "bg-berry after:bg-berry before:bg-berry"
      : order % 5 === 1
      ? "bg-berry after:bg-berry before:bg-berry"
      : order % 5 === 2
      ? "bg-berry after:bg-berry before:bg-berry"
      : order % 5 === 3
      ? "bg-berry after:bg-berry before:bg-berry"
      : "bg-berry after:bg-berry before:bg-berry"

  return letter === "l" ? (
    <div className="container flex flex-col flex-col-reverse w-full md:flex-row md:p-0">
      <div className={cn(styles.textWrapper, styles.leftL, color)}>
        {title && <h3>{title}</h3>}
        {reference?.hero_description && (
          <p className={styles.contentHeroDescription}>
            {reference.hero_description}
          </p>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.imageL}>
          {titleImage && (
            <Image
              src={titleImage.permalink}
              alt={titleImage.alt}
              width={titleImage.width}
              height={titleImage.height}
              sizes="(max-width: 767px) 100vw, 50vw"
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="container flex flex-col flex-col-reverse w-full md:flex-row md:p-0">
      <div className={cn(styles.textWrapper, styles.leftV, color)}>
        <div className={cn(styles.leftInner, color)}>
          {title && <h3>{title}</h3>}
          {reference?.hero_description && (
            <p className={styles.contentHeroDescription}>
              {reference.hero_description}
            </p>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.image}>
          {titleImage && (
            <Image
              src={titleImage.permalink}
              alt={titleImage.alt}
              layout="intrinsic"
              width={titleImage.width}
              height={titleImage.height}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const ReferencesSlider = ({ data }) => {
  const sliderRef = useRef(null)
  const {
    type,
    dynamic_headline: headline,
    headline_type: headlineType,
    headline_appearance: headlineAppearance,
  } = data

  const settings = {
    centerMode: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  }

  return type ? (
    <Section
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
      className={styles.sectionWrapper}
    >
      <div className={styles.containerStart}>
        <DynamicHeadline
          headlineType={headlineType.value}
          headlineAppearance={headlineAppearance.value}
          className={`${styles.headline}`}
        >
          {headline}
        </DynamicHeadline>
        <div className={styles.navButton}>
          <button
            className={cn("button", styles.prevButton, styles.highlight)}
            aria-label="Zur vorherigen Referenz wechseln"
            onClick={() => sliderRef?.current?.slickPrev()}
          >
            <ArrowLeftIcon aria-hidden />
          </button>
          <button
            className={cn("button", styles.nextButton, styles.highlight)}
            aria-label="Zur nächsten Referenz wechseln"
            onClick={() => sliderRef?.current?.slickNext()}
          >
            <ArrowRightIcon aria-hidden />
          </button>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {data?.entries?.map((reference, i) => (
          <Slide key={i} reference={reference} order={i} />
        ))}
      </Slider>
      <div className="container mt-4">
        <LinkButton
          className="button--inverted"
          data={{
            link_url: "/referenzen",
            link_text: "Zu den Case Studies",
            show_arrow: "right",
          }}
        />
      </div>
    </Section>
  ) : null
}

export default ReferencesSlider
