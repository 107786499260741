import cn from "classnames"
import parse from "html-react-parser"
import Image from "../../Image"
import LetterImage from "../../ui/LetterImage"
import styles from "./BlockQuote.module.scss"
import Letter from "../../ui/Letter"
import Shape from "../../ui/Shape"

const BlockQuote = ({ data, fillColor }) => {
  const { author, quote_text: quoteText, author_position } = data
  return (
    <li
      className={cn(styles.blockQuote, {
        [styles.blockQuoteRight]: author_position.value === "right",
        [styles.noImage]: !author.avatar,
      })}
    >
      <div className={styles.cite}>
        <div className={styles.imageWrapper}>
          {author.avatar ? (
            <Image
              src={author.avatar.permalink}
              alt={author.avatar.alt}
              fill
              style={{ objectPosition: author.avatar.focus_css }}
              sizes="(max-width: 639px) 25vw, (max-width: 1023px) 33vw, 25vw"
            />
          ) : (
            <div className={styles.placeholder}>
              <Shape type="rect-vertical-wide" fill={fillColor} />
              <Shape type="rect-vertical-wide" fill={fillColor} />
            </div>
          )}
        </div>
        <div className={styles.authorWrapper}>
          <span className={cn(styles.author, "quote")}>{author.title}</span>
          <span className={styles.position}>
            {`${author.position}`}
            {author.company && (
              <span className="inline">{` bei ${author.company}`}</span>
            )}
          </span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.marks}>
          <Shape type="rect-vertical-wide" fill={fillColor} />
          <Shape type="rect-vertical-wide" fill={fillColor} />
        </div>
        <blockquote className={styles.quote}>
          {quoteText && (
            <div className={cn(styles.text, "quote")}>{parse(quoteText)}</div>
          )}
        </blockquote>
      </div>
    </li>
  )
}

export default BlockQuote
